import { useEffect, useState } from "react";
import moment from "moment";
import Table from './components/Table'
// import * as XLSX from 'xlsx';
import XLSX from 'sheetjs-style';
import TableInput from './components/TableInput'
import TableMenu from './components/TabeMenu'
import { styleTitle, styleLineThroght } from './components/contants';
import UploadFile from '../Common/UploadFile'

export default function Home2(props) {
  const [dataAllPage, setDataAllPage] = useState(props?.dataSave)
  const [pageActive, setPageActive] = useState(0)
  const [nameFileExport, setNameFileExport] = useState('')
  const [tenquyen, setTenquyen] = useState('')

  useEffect(() => {
    const dateAllPageOld = localStorage.getItem('dataAllPage') ? JSON.parse(localStorage.getItem('dataAllPage')) : []
    setDataAllPage([...dateAllPageOld])
    setNameFileExport(localStorage.getItem('nameFile'))
    setTenquyen(localStorage.getItem('tenquyen'))
  }, [])

  useEffect(() => {
    if (props?.dataInsert) {
      props?.dataInsert(dataAllPage)
    }
  }, [dataAllPage])

  const handleChangeValueInput = (value, setDataValue, name) => {
    setDataValue(value)
    localStorage.setItem(name, value)
  }

  const fileNameExport = `${nameFileExport ? nameFileExport : 'filename'}_${moment().unix()}`

  // function exportFile() {
  //   if (dataAllPage.length < 1) {
  //     alert('Bạn không thể export khi chưa thêm thông tin')
  //     return
  //   }
  //   var table_elt = document.getElementById(`menu_table`);
  //   var workbook = XLSX.utils.table_to_book(table_elt);
  //   var ws = workbook.Sheets["Sheet1"];
  //   XLSX.utils.book_append_sheet(workbook, ws, 'DANH_MUC');

  //   dataAllPage.map((item, key) => {
  //     let nameSheet = item?.fullname1 ? convertToSlug(item?.fullname1).toUpperCase() + '_TRANG_' + (item?.page_sheet) : `SHEET_${(key + 1)}`
  //     var table_elt2 = document.getElementById(`tbl${key}`);
  //     var workbook2 = XLSX.utils.table_to_book(table_elt2);
  //     var ws2 = workbook2.Sheets["Sheet1"];
  //     XLSX.utils.book_append_sheet(workbook, ws2, nameSheet);
  //   })
  //   workbook.SheetNames.shift();
  //   delete workbook.Sheets["Sheet1"]
  //   let workbookMake = handleChangeFunction(workbook.Sheets)
  //   workbookMake = handleMakeStype(workbook.Sheets)
  //   workbook.Sheets = workbookMake
  //   XLSX.writeFile(workbook, `${fileNameExport}.xlsx`);
  // }

  const handleChangeFunction = (dataMake) => {
    Object.keys(dataMake).map((item, key) => {
      if (item !== 'DANH_MUC') {
        dataMake[item].A1.f = `=HYPERLINK("[${fileNameExport}.xlsx]DANH_MUC!A1", "Quay về trang danh mục")`
      } else {
        let keyValue = 1;
        Object.keys(dataMake[item]).map((item2, key2) => {
          const number = Number(item2.substring(1, 4));
          if (item2.charAt(0) === 'B' && number !== NaN && number >= 2 && dataMake[item][item2]?.v) {
            const nameValue = convertToSlug(dataMake[item][item2].v).toUpperCase()
            dataMake[item][item2].f = `=HYPERLINK("[${fileNameExport}.xlsx]${nameValue}!A1", "${dataMake[item][item2].v}")`
            keyValue++;
          }
        })
      }
    })

    return dataMake
  }

  const handleMakeStype = (dataMake) => {
    Object.keys(dataMake).map((item, key) => {
      Object.values(dataMake[item]).map((r1, k1) => {
        if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('I-NGƯỜI SỬ D') > -1) {
          dataMake[item][Object.keys(dataMake[item])[k1]].s = styleTitle
        } else if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('II- THỬA ') > -1) {
          dataMake[item][Object.keys(dataMake[item])[k1]].s = styleTitle
        } else if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('III - NHỮNG THAY ĐỔI TRO') > -1) {
          dataMake[item][Object.keys(dataMake[item])[k1]].s = styleTitle
        } else if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('throght-line') > -1) {
          const numberLine = Object.keys(dataMake[item])[k1].slice(1, 3)
          dataMake[item][`A${numberLine}`].s = styleLineThroght
          dataMake[item][`B${numberLine}`].s = styleLineThroght
          dataMake[item][`C${numberLine}`].s = styleLineThroght
          delete dataMake[item][Object.keys(dataMake[item])[k1]];
        } else if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('throght-thua') > -1) {
          const numberLine = Object.keys(dataMake[item])[k1].slice(1, 3)
          dataMake[item][`A${numberLine}`].s = styleLineThroght
          dataMake[item][`B${numberLine}`].s = styleLineThroght
          dataMake[item][`C${numberLine}`].s = styleLineThroght
          delete dataMake[item][Object.keys(dataMake[item])[k1]];
        } else if (r1?.v && typeof r1?.v === 'string' && r1?.v.indexOf('LINK_TO_SHEET_') > -1) {
          const nameSheetValue = dataMake[item][Object.keys(dataMake[item])[k1]].v.replace('LINK_TO_SHEET_', '')
          dataMake[item][Object.keys(dataMake[item])[k1]].f = `=HYPERLINK("[${fileNameExport}.xlsx]${nameSheetValue}!A1", "Chuyển tiếp")`
        }
      })
    })

    return dataMake
  }

  const handleAddPage = () => {
    dataAllPage.push({})
    setDataAllPage([...dataAllPage])
  }

  function convertToSlug(str) {
    // str = str.toLowerCase();
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    str = str.replace(/[đĐ]/g, 'd');
    // str = str.replace(/([^0-9a-z-\s])/g, '');
    str = str.replace(/(\s+)/g, '_');
    str = str.replace(/-+/g, '_');
    // str = str.replace(/^-+|-+$/g, '');
    return str;
  }

  const handleRemoveAll = () => {
    if (window.confirm("Bạn có muốn làm mới? Sẽ bị mất hết dữ liệu nếu đồng ý.")) {
      const res = localStorage.getItem('dataAllPage')
      localStorage.setItem('dataAllPageOld', res)
      localStorage.removeItem('dataAllPage')
      localStorage.removeItem('nameFile')
      localStorage.removeItem('tenquyen')
      window.location.reload();
    }

  }

  const handleRemoveSheet = () => {
    let dataAllPageOld = dataAllPage
    dataAllPageOld.pop();
    setDataAllPage([...dataAllPageOld])
    localStorage.setItem('dataAllPage', JSON.stringify(dataAllPageOld))
  }

  const handleExportFileData = async () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dataAllPage)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${fileNameExport}_${moment().unix()}.json`;

    link.click();
  }

  const handleChangeImportFile = (event) => {
    var fr = new FileReader();
    fr.onload = function () {
      const dataImportPage = JSON.parse(fr.result)
      setDataAllPage(dataImportPage)
    }
    fr.readAsText(event.target.files[0]);
  }

  const handleSaveImportExcel = (value) => {
    setDataAllPage([...value])
    localStorage.setItem('dataAllPage', JSON.stringify(value))
  }

  const handleDeleteItem = (key) => {
    delete dataAllPage[key]
    var filtered = dataAllPage.filter(function (el) {
      return el != null;
    });
    localStorage.setItem('dataAllPage', JSON.stringify(filtered))
    setDataAllPage([...filtered])
  }

  return (
    <div style={{ padding: 0 }}>
      <UploadFile params={{ id: 1 }} dataPush={(value) => handleSaveImportExcel(value)} />
      <div style={{ marginTop: 10 }}>
        <input type={'file'} onChange={(event) => handleChangeImportFile(event)} />
        <button className="vowvrqnxli" onClick={() => handleRemoveAll()}>Xóa hết dữ liệu</button>
        <button className="vowvrqnxli" onClick={() => handleExportFileData()}>Save File</button>
      </div>
      <button className="vowvrqnxli" onClick={() => handleRemoveSheet()}>Xóa sheet</button>
      {/* <button className="vowvrqnxli" onClick={() => exportFile()}>Export to Excel</button> */}
      <button className="vowvrqnxli" onClick={() => handleAddPage()}>Thêm trang</button>
      <input value={nameFileExport} onChange={(event) => handleChangeValueInput(event.target.value, setNameFileExport, 'nameFile')} placeholder="Tên file xuất" />
      <input value={tenquyen} onChange={(event) => handleChangeValueInput(event.target.value, setTenquyen, 'tenquyen')} placeholder="Tên quyển nhập" />
      <div className={`ioogqnyxhj`}>
        {
          dataAllPage && dataAllPage.length > 0 ? dataAllPage.map((item, key) => {
            return (
              <div className={`pgxnslbqhg ${pageActive == key ? 'twbifjlqvc' : ''}`} key={key}>
                <div onClick={() => setPageActive(key)}>
                  {item?.fullname1 ? convertToSlug(item.fullname1).toUpperCase() + `_TRANG_${item?.page_sheet}` : `SHEET_${key + 1}`}
                </div>
                <div className="tfrftoubdd" onClick={() => handleDeleteItem(key)}>X</div>
              </div>
            )
          }) : null
        }
      </div>
      {
        dataAllPage.length > 0 ? <TableInput UpdateData={(value) => {
          dataAllPage[pageActive] = value
          setDataAllPage([...dataAllPage])
          localStorage.setItem('dataAllPage', JSON.stringify(dataAllPage))
        }} pageActive={pageActive} dataAllPage={dataAllPage} item={dataAllPage[pageActive]} /> : null
      }

      {/* {
        dataAllPage && dataAllPage.length > 0 ? dataAllPage.map((item, key) => {
          return (
            <Table pageActive={pageActive} item={item} keyValue={key} />
          )
        }) : null
      } */}
      {/* <TableMenu dataAllPage={dataAllPage} tenquyen={tenquyen} /> */}
    </div>
  )
}