import axios from "axios";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { removeAscent, urlBaseGetImage } from "../../Helper/helpFunction";

export default function Danhmuc() {
  const params = useParams();
  const id = params?.id;
  const [name, setName] = useState('')
  const [data, setData] = useState([])

  useEffect(() => {
    fetch();
  }, [])

  const fetch = async () => {
    const res = await axios.get(`${urlBaseGetImage(`/api/village/Export2danhmuc/${id}`)}`)
    if (res?.status === 200) {
      console.log(res?.data, 'res');
      setData(res?.data?.data?.parcel_of_land_info)
      setName(res?.data?.data?.name)
    }
  }

  data.map((item) => {
    if (item.fullname1) {
      const name = item.fullname1.split(" ");
      const lastName = name[name.length - 1] + '-' + item?.id;
      const textFirst = lastName.charAt(0);
      item.nameSort = lastName;
      item.firstText = textFirst;
    }
  })
  data.sort(compareValues('nameSort', 'asc'));
  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].localeCompare(b[key]);

      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  let listData = {}
  data.map((item) => {
    if (item.firstText) {
      const text = removeAscent(item.firstText.toLowerCase())
      if (text) {
        if (!listData?.[text]) {
          listData[text] = {
            data: []
          }
        }
        listData[text].data.push(item);
      }

    }
  })

  let count = 0;
  return (
    <>
      {/* <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`${name}`}
        sheet="tablexls"
        buttonText="Download Excel" /> */}
      <table id="table-to-xls">
        <tr>
          <td colSpan="5" style={{ fontSize: '13px', textAlign: 'center', border: '1px solid #000000' }}>
            môc lôc ng­êi sö dông ®Êt
          </td>
        </tr>
        <tr>
          <td rowSpan={2} style={{ fontSize: '16px', border: '1px solid #000000', fontWeight: 'bold' }}>
            Số thứ tự
          </td>
          <td rowSpan={2} style={{ fontSize: '16px', border: '1px solid #000000', fontWeight: 'bold' }}>
            Tên mục
          </td>
          <td colSpan={2}>§¨ng ký t¹i sæ</td>
          <td rowSpan={2} style={{ fontSize: '14px', border: '1px solid #000000', fontWeight: 'bold' }}>Số thứ tự</td>
        </tr>
        <tr>
          <td style={{ fontSize: '14px', border: '1px solid #000000', fontWeight: 'bold' }}>
            Quyển số
          </td>
          <td style={{ fontSize: '14px', border: '1px solid #000000', fontWeight: 'bold' }}>Trang số</td>
        </tr>
        {
          listData && Object.keys(listData) ? Object.values(listData).map((item, key) => {
            return <tbody key={key}>
              <tr >
                <td style={{ fontSize: '14px', textAlign: 'center', border: '1px solid #000000', fontWeight: 'bold' }} colSpan={5}>
                  VẦN {Object.keys(listData)[key].toUpperCase()}
                </td>
              </tr>
              {
                item.data.map((item2, key2) => {
                  count++;
                  return <tr key={`k-${key2}`}>
                    <td style={{ fontSize: '14px', border: '1px solid #000000' }}>{count}</td>
                    <td style={{ fontSize: '14px', border: '1px solid #000000' }}>{item2.fullname1}</td>
                    <td style={{ fontSize: '14px', border: '1px solid #000000' }}></td>
                    <td style={{ fontSize: '14px', border: '1px solid #000000' }}>{item2.page_sheet}</td>
                    <td style={{ fontSize: '14px', border: '1px solid #000000' }}></td>
                  </tr>
                })
              }
            </tbody>


          }) : null
        }

      </table>
    </>
  )
}